<div class="pt-5">
  <div class="card boxContainer mt-2">
    <div class="text-dark font-size-32 mb-3">
      Reset Password
    </div>
    <div *ngIf="!show">
      <form nz-form [nzLayout]="'vertical'" [formGroup]="forgotPasswordForm" (ngSubmit)="submitForm()" role="form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your email!">
            <input type="text" formControlName="email" nz-input placeholder="Email Address" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [nzLoading]="loading" class="text-center w-100" style="height: 50px;">
              <strong>Send OTP</strong>
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>

    <div *ngIf="show">
      <form nz-form [nzLayout]="'vertical'" [formGroup]="changePasswordForm" (ngSubmit)="submitForm2()" role="form">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your email!">
            <input type="text" formControlName="email" readonly value={{disabled_email}} nz-input
              placeholder="Email Address" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <input type="password" nz-input formControlName="password" placeholder="Password" (paste)="onPaste($event)" (copy)="onCopy($event)" (cut)="onCut($event)" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <input type="password" nz-input formControlName="confpassword" placeholder="Confirm Password" (paste)="onPaste($event)" (copy)="onCopy($event)" (cut)="onCut($event)" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button nz-button nzType="primary" [nzLoading]="loading" class="text-center w-100" style="height: 50px;">
              <strong>Submit</strong>
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>


    <a routerLink="/auth/login" class="vb__utils__link">
      <i class="fe fe-arrow-left mr-1 align-middle"></i>
      Go to Sign in
    </a>
  </div>
</div>