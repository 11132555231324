
<div class="footer"  >
  <div class="footerInner">
    <p class="mb-0">
      Copyright © {{ date }}
      <a href="https://moxiedb.com"  target="_blank" rel="noopener noreferrer">
        moxieDB.com
      </a>
      |
      <a href="https://dev.moxiedb.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
      |
      <a href="https://dev.moxiedb.com/legal/general-data-protection-regulation" target="_blank"
         rel="noopener noreferrer">
        GDPR
      </a>
      |
      <a href="https://dev.moxiedb.com/legal/terms-of-use" target="_blank" rel="noopener noreferrer">
        Terms of Use
      </a>
    </p>
  </div>
</div>
