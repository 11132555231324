import { Component } from '@angular/core'

@Component({
  selector: 'kit-bootstrap-form-example',
  templateUrl: './form.component.html',
})
export class KitBootstrapFormExampleComponent {
  onPaste(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCopy(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCut(event: ClipboardEvent): void {
    event.preventDefault()
  }
}
