import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { HttpService } from '../../services/http/http.service'

@Component({
  selector: 'app-user-redirect',
  templateUrl: './user-redirect.component.html',
  styleUrls: ['./user-redirect.component.css'],
})
export class UserRedirectComponent implements OnInit {
  constructor(private router: Router, private services: HttpService) {
    // this.getUserData();
  }

  ngOnInit() {
    this.router.navigate(['user-init'])
  }

  // getUserData() {
  //
  //   const request = {
  //     method: 'GET',
  //     action_url: 'user/get_user_profile'
  //   };
  //   this.services.doHttp(request).subscribe(
  //     res => {
  //
  //     },
  //     error => {
  //
  //     });
  // }
}
