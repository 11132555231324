import { Component, OnInit } from '@angular/core'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { HttpService } from 'src/app/services/http/http.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'

@Component({
  selector: 'vb-system-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../style.component.scss'],
  styles: [
    `
      input {
        height: 50px;
      }
    `,
  ],
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup
  changePasswordForm: FormGroup
  loading: boolean = false
  disabled_email: string = ''
  otp: string

  show: boolean = false

  constructor(
    private fb: FormBuilder,
    private store: Store<any>,
    private httpService: HttpService,
    private router: Router,
    private notification: NzNotificationService,
  ) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.loading = state.loading
    })
  }

  forgotPasswordFormFunc() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  changePasswordFormFunc() {
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      confpassword: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.forgotPasswordFormFunc()
    this.changePasswordFormFunc()
  }

  submitForm() {
    let data = {
      email: this.forgotPasswordForm.value.email,
    }
    const request = {
      params: data,
      method: 'POST',
      action_url: 'user/forgot_password',
    }

    this.httpService.doHttp(request)?.subscribe(
      (res: any) => {
        if (res.status) {
          this.disabled_email = res.email
          this.show = true
        } else {
        }
      },
      err => {
        if (err.status == 403) {
          this.router.navigate(['/auth/forbidden-page'])
        }
        this.notification.create('error', 'Failed!', err.error.msg, {
          nzPlacement: 'bottomRight',
        })
        this.forgotPasswordForm.reset()
      },
    )
  }

  onOtpChange(event) {
    this.otp = event
  }

  submitForm2() {
    let data = {
      otp: this.otp,
      email: this.forgotPasswordForm.value.email,
      password: this.changePasswordForm.value.password,
      confpassword: this.changePasswordForm.value.confpassword,
    }
    const request = {
      params: data,
      method: 'POST',
      action_url: 'user/change_password',
    }

    this.httpService.doHttp(request)?.subscribe(
      (res: any) => {
        if (res.status) {
          this.disabled_email = res.email
          this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
          this.router.navigate(['auth/login'])
        } else {
        }
      },
      err => {
        if (err.status == 403) {
          this.router.navigate(['/auth/forbidden-page'])
        }
        this.notification.create('error', 'Failed!', err.error.msg, {
          nzPlacement: 'bottomRight',
        })
        this.changePasswordForm.reset()
      },
    )
  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCopy(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCut(event: ClipboardEvent): void {
    event.preventDefault()
  }
}
