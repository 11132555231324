import { Component, OnInit } from '@angular/core'
import { HttpService } from '../../../../services/http/http.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'

@Component({
  selector: 'vb-widgets-general-14',
  templateUrl: './14.component.html',
  styleUrls: ['./14.component.scss'],
})
export class VbGeneral14Component implements OnInit {
  profilePic: any = 'assets/images/default.png'
  sentMsg: Boolean = false
  selectedType: string
  message: string

  constructor(private services: HttpService, private notification: NzNotificationService) {
    if (this.services.userData) {
      this.profilePic = this.services.userData.logoUrl
    }
  }
  ngOnInit() {}

  onRadioChange(event: string) {
    this.selectedType = event
  }

  sendMessage() {
    const request = {
      params: { message: this.message, selectedType: this.selectedType },
      method: 'POST',
      action_url: 'user/compliant?user_id=' + this.services.userData.userId,
    }
    this.services.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status) {
          // this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
          this.sentMsg = true
          this.ngOnInit()
        }
      },
      (error: any) => {
        // this.notification.create('error', 'Failed', error.error.msg, {
        //   nzPlacement: 'bottomRight',
        // })
      },
    )
  }

  more() {
    this.sentMsg = false
    this.selectedType = null
    this.message = null
  }
}
