import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Router} from '@angular/router'
import {Observable} from 'rxjs/index'
import {NotifierService} from 'angular-notifier'
import {userEnvironment} from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any = false
  public userData: any = false
  public playingRole: any = false
  public menuData: any = []

  public validations = {
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    otp: /^\d{6}$/,
  }
  public baseUrl: any = '/api/apiv1/'

  // Idle logout after 10 minutes

  logoutTime: any = ''

  timoutWarning: any = 540000
  timoutNow: any = 60000
  warningTimer: any
  timeoutTimer: any
  ResetTimeOutEnable: any = false
  lastApiCalled: any = 0
  openSessionExpiry: any = false
  sessionExpiryIn: any = 60
  warningTime: any = 0
  warningTimeInterval: any
  sessionExpiryInterval: any

  lastActivity: any = ''
  enableActivity: any = false

  public vendorCertifyDoc: any = {}

  constructor(private http: HttpClient,
              private notification: NotifierService,
              private router: Router,) {
    setInterval(() => {
      if (this.enableActivity) {
        const lastAct = localStorage.getItem('last_activity')
        if (lastAct) {
          if (this.lastActivity !== lastAct) {
            this.logoutTime = Date.now() + 600000
            this.lastActivity = lastAct
          }
        } else {
          this.logout()
        }

        if (this.logoutTime < Date.now()) {
          this.logout()
        } else if (this.logoutTime - 60000 > Date.now()) {
          this.openSessionExpiry = false
        } else {
          this.openSessionExpiry = true
          this.sessionExpiryIn = Math.trunc((this.logoutTime - Date.now()) / 1000)
        }
      }
    }, 1000)

    this.verifyExistingToken()

    document.addEventListener(
      'mousemove',
      function () {
        this.upDateActivity()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'mousedown',
      function () {
        this.upDateActivity()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'keypress',
      function () {
        this.upDateActivity()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'touchmove',
      function () {
        this.upDateActivity()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'onscroll',
      function () {
        this.upDateActivity()
      }.bind(this),
      false,
    )
  }

  public doHttp(request) {
    this.lastApiCalled = Date.now()
    this.startLogoutTimers()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, JSON.stringify(request.params), {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  public doHttp1(request) {
    this.lastApiCalled = Date.now()
    this.startLogoutTimers()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  public doHttp3(request) {
    this.lastApiCalled = Date.now()
    this.startLogoutTimers()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('Content-Type', 'text/plain; charset=utf-8')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options, responseType: 'text'
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  verifyExistingToken() {
    const existingToken = localStorage.getItem('tokenID')
    if (existingToken) {
      const request = {
        method: 'GET',
        action_url: 'user/check_existing_token?t=' + existingToken,
      }
      this.doHttp(request).subscribe(
        (res: any) => {
          if (res.status) {
            this.token = localStorage.getItem('tokenID')
            const menudata = localStorage.getItem('menuData')
            if (this.token) {
              this.enableActivity = true
              this.clearTimers()
              this.startLogoutTimers()
              if (localStorage.getItem('userData')) {
                this.userData = JSON.parse(localStorage.getItem('userData'))
              }

              this.playingRole = localStorage.getItem('playingRole')
              if (menudata) {
                this.menuData = JSON.parse(menudata)
              }
            }
          } else {
            this.enableActivity = false
            this.token = false
            this.userData = false
            this.removeItem('tokenID')
            this.removeItem('userData')
            this.router.navigate(['/auth/login'])
          }
        },
        error => {
        },
      )
    }
  }

  public activeTrigger() {
    this.lastApiCalled = Date.now()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }
    return this.http.post(
      this.baseUrl + 'user/active_trigger',
      {},
      {
        headers: options,
      },
    )
  }

  public releaseComplianceDocEdit(doc_id) {
    if (doc_id) {
      const request = {
        method: 'put',
        action_url: 'compliance/release_edit_lock?doc_id=' + doc_id,
        params: {},
      }
      this.doHttp(request).subscribe(
        (res: any) => {
          if (res.status) {
          } else {
          }
        },
        error => {
        },
      )
    }
  }

  /**
   * store user data
   * @param data
   */
  public storeUserData(data) {
    this.token = data['tokenID']
    this.setItem('token', data['tokenID'])
  }

  /* set key value from local storage */

  public setItem(key, item) {
    window.localStorage.setItem(key, item)
  }

  /* get key value from local storage */

  public getItem(key) {
    const temp = window.localStorage.getItem(key)
    return temp
  }

  public removeItem(key) {
    window.localStorage.removeItem(key)
  }

  public errorMsg(error) {
    if (error.status === 401) {
      if (error.error.auth_declined) {
      } else {
        this.logout()
      }
    }
  }

  success_notification(msg) {
    this.notification.notify('success', msg)
  }

  error_notification(msg) {
    this.notification.notify('error', msg)
  }

  public logout() {
    const request = {
      method: 'GET',
      action_url: 'user/logout?token=' + this.token,
    }
    this.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.token = false
          this.userData = false
          this.removeItem('tokenID')
          this.removeItem('userData')
          this.removeItem('last_activity')
          this.enableActivity = false
          this.openSessionExpiry = false
          this.router.navigate(['/auth/login'])
        }
      },
      error => {
        this.softLogout()
      },
    )
  }

  public softLogout() {
    this.token = false
    this.userData = false
    this.removeItem('tokenID')
    this.removeItem('userData')
    this.removeItem('last_activity')
    this.enableActivity = false
    this.openSessionExpiry = false
    this.router.navigate(['/auth/login'])
  }

  public sliceData(msg) {
    if (msg) {
      const msges = msg.split('\n\n')
      if (msges.length > 0) {
        if (msges.length == 1) {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          }
        } else {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          } else {
            if (msges[1].length > 35) {
              return msges[0] + msges[1].substring(0, 35)
            } else {
              return msges[0] + msges[1]
            }
          }
        }
      } else {
        return msg
      }
    } else {
      return msg
    }
  }

  clearTimers() {
    this.logoutTime = ''
    clearTimeout(this.warningTimer)
  }

  upDateActivity() {
    if (this.logoutTime !== '' && Math.trunc((this.logoutTime - Date.now()) / 1000) > 60) {
      localStorage.setItem('last_activity', Date.now().toString())
      if (this.lastApiCalled < Date.now() - 30000) {
        this.activeTrigger().subscribe(res => {
        })
      }
    }
  }

  startLogoutTimers() {
    this.logoutTime = Date.now() + 600000
  }

  public doHttp2(request) {
    this.lastApiCalled = Date.now()
    this.startLogoutTimers()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(request.action_url, {
        headers: options,
      })
    }
  }
}
