<div class="chat">
  <a role="button" (click)="toggle()" class="vb__utils__sidebar__button vb__utils__sidebar__button__long">
    <i class="fe fe-message-square mr-md-2"></i>
    <span class="d-none d-md-inline">Chat</span>
  </a>
  <div class="chatContainer" [ngClass]="isSupportChatOpen ? 'containerToggled' : ''">
    <div class="d-flex flex-wrap mb-2">
      <div class="text-dark font-size-18 font-weight-bold mr-auto">Chat</div>
      <button type="button" class="btn btn-link p-0 border-0" (click)="toggle()">
        <i class="fe fe-x-square font-size-21 align-middle text-gray-6"></i>
      </button>
    </div>
    <vb-widgets-general-14></vb-widgets-general-14>
  </div>
</div>