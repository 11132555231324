import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/@vb/components/Guard/auth.guard'
import { WidgetsComponentsModule } from 'src/app/@vb/widgets/widgets-components.module'

import { UserGuardService } from 'src/app/guards/user-guard.service'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// pages
// VB:REPLACE-START:ROUTER-IMPORTS
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { UserRedirectComponent } from './utility/user-redirect/user-redirect.component'
import { UserTokenService } from './tokenResolvers/user-token.service'
// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  {
    path: '',
    // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  // {
  //   path: '',
  //   component: LayoutMainComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     // VB:REPLACE-START:ROUTER-CONFIG
  //     {
  //       path: 'dashboard',
  //       data: { title: 'Dashboard' },
  //       component: DashboardComponent,
  //     },
  //
  //     // VB:REPLACE-END:ROUTER-CONFIG
  //   ],
  // },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  { path: 'home', loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule) },
  { path: 'user-utility', component: UserRedirectComponent, resolve: { access: UserTokenService } },
  {
    path: 'user-init',
    loadChildren: () => import('./user-init/user-init.module').then(mod => mod.UserInitModule),
  },
  { path: 'user', loadChildren: () => import('./user/user.module').then(mod => mod.UserModule) },
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(mod => mod.LegalModule),
  },

  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: 'legacy',
    }),
    LayoutsModule,
    WidgetsComponentsModule,
  ],
  declarations: [
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    DashboardComponent,

    // VB:REPLACE-END:ROUTER-DECLARATIONS
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
