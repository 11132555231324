import { Injectable } from '@angular/core'

import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor() {}

  messageNewCount = 0 //for left menu
}
