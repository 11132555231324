import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'
import { AngularFireModule } from '@angular/fire'
import { AngularFireAuthModule } from '@angular/fire/auth'
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { firebaseConfig, firebaseAuthService } from './services/firebase'
import { jwtAuthService } from './services/jwt'
import { MockHttpCallInterceptor } from './services/fakeApi'

// locale resistration
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd/i18n'
import { NotifierModule } from 'angular-notifier'

// import {UploadFilesService} from './services/fileUpload/upload-files.service'
// import {ChatService} from './services/chat/chat.service'

import { ConnectionServiceModule } from 'ng-connection-service'
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io'
import { userEnvironment } from '../environments/environment'
import { CookieService } from 'ngx-cookie-service'
// import { EditTaskViewComponent } from './user/checklist/edit-task-view/edit-task-view.component';
// import { TaskDetailsViewComponent } from './user/checklist/task-details-view/task-details-view.component';
// import { CreateTaskViewComponent } from './user/checklist/create-task-view/create-task-view.component';
// import { EditChecklistViewComponent } from './user/checklist/edit-checklist-view/edit-checklist-view.component';
// import { ChecklistDetailsViewComponent } from './user/checklist/checklist-details-view/checklist-details-view.component';
// import { CreateChecklistViewComponent } from './user/checklist/create-checklist-view/create-checklist-view.component';
// import { TaskDashboardViewComponent } from './task-dashboard-view/task-dashboard-view.component'

const config: SocketIoConfig = { url: userEnvironment.socketURL, options: {} }

const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'en' },
  { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localeEn, 'en')

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NotifierModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,

    // init firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SocketIoModule.forRoot(config),
    ConnectionServiceModule,
  ],
  providers: [
    CookieService,
    // auth services
    firebaseAuthService,
    jwtAuthService,
    // UploadFilesService,

    // fake http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpCallInterceptor,
      multi: true,
    },

    // locale providers
    ...LOCALE_PROVIDERS,
    // UploadFilesService,
    // ChatService,
    // firestore settings
    { provide: SETTINGS, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
