import { Component, OnInit, Input } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash'
import { select, Store } from '@ngrx/store'
import { MenuService } from 'src/app/services/menu'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'

import { HttpService } from '../../../../services/http/http.service'

import { ChatService } from '../../../../services/chat/chat.service'
import { element } from 'protractor'
import { RequestService } from 'src/app/services/request/request.service'
import { TracktasksService } from 'src/app/services/tracktasks/tracktasks.service'

@Component({
  selector: 'vb-menu-classic-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
  styles: [
    `
      nz-badge {
        margin-right: 20px;
        vertical-align: text-top;
      }

      nz-badge.ant-badge-rtl {
        margin-right: 0;
        margin-left: 20px;
      }

      .head-example {
        width: 42px;
        height: 42px;
        border-radius: 4px;
        background: #eee;
        display: inline-block;
        vertical-align: middle;
      }
    `,
  ],
})
export class MenuClassicLeftComponent implements OnInit {
  menuColor: String
  isMenuShadow: Boolean
  isMenuUnfixed: Boolean
  isSidebarOpen: Boolean
  isMobileView: Boolean
  leftMenuWidth: Number
  isMenuCollapsed: Boolean
  logo: String
  menuData: any[]
  menuDataActivated: any[]
  role: String
  userData

  chatResponse: any = []
  unreadCount = 0
  messagesBar = false

  constructor(
    private menuService: MenuService,
    private store: Store<any>,
    private router: Router,
    public services: HttpService,
    public chatService: ChatService,
    public requestService: RequestService,
    public tracktasksService: TracktasksService,
  ) {
    this.userData = JSON.parse(this.services.getItem('userData'))

    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.role
    })
    this.menuService.getMenuData().subscribe(menuData => {
      this.menuData = menuData
      this.loadMenu()
    })
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.menuColor = state.menuColor
      this.isMenuShadow = state.isMenuShadow
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isSidebarOpen = state.isSidebarOpen
      this.isMobileView = state.isMobileView
      this.leftMenuWidth = state.leftMenuWidth
      this.isMenuCollapsed = state.isMenuCollapsed
      this.logo = state.logo
    })
  }

  ngOnInit() {}

  loadMenu() {
    this.activateMenu(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.activateMenu(event.url ? event.url : null)
      })
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData))
    const pathWithSelection = this.getPath({ url: url }, menuData, (entry: any) => entry, 'url')
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true
      _.each(pathWithSelection, (parent: any) => (parent.open = true))
    }
    this.menuDataActivated = menuData.slice()
    // this.menuDataActivated.push({
    //   children: [],
    //   forRole: 'iuser',
    //   icon: 'fe fe-server',
    //   key: '__intranet',
    //   ptype: 'p',
    //   title: 'Intranet',
    //   url: 'intranet',
    //   v0: '6319a21ic9e0a0eged6f5dk4',
    //   v1: 'intranet',
    //   v2: 'yes',
    // })
  }

  getPath(
    element: any,
    source: any,
    property: any,
    keyProperty = 'key',
    childrenProperty = 'children',
    path = [],
  ) {
    let found = false
    const getElementChildren = (value: any) => _.get(value, childrenProperty)
    const getElementKey = (value: any) => _.get(value, keyProperty)
    const key = getElementKey(element)
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key) {
          path.push(e)
          return true
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e),
          ))
        }
      }) &&
      (found || _.map(path, property))
    )
  }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    )
  }

  onCollapse(value: any) {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value,
      }),
    )
  }

  messagesBarToggle() {
    this.messagesBar = !this.messagesBar
  }
}
