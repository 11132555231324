import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { HttpService } from '../http/http.service'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(public services: HttpService, private http: HttpClient) {}

  getMenuData(): Observable<any[]> {
    return of(this.services.menuData)
  }
}
