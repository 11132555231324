<div class="subbar">
  <ul class="breadcrumbsList mr-4">
    <li class="breadcrumbItem">
      <a href="#" class="breadcrumbLink">
        Main
      </a>
    </li>
    <ng-container *ngFor="let item of breadcrumbs; let last = last;">
      <li *ngIf="!last" class="breadcrumbItem">
        <span class="breadcrumbLink">
          {{ item.title }}
        </span>
      </li>
      <li *ngIf="last" class="breadcrumbItem">
        <span class="breadcrumbLink breadcrumbLink__current">
          {{ item.title }}
        </span>
      </li>
    </ng-container>
  </ul>
  <div class="divider mr-4 d-none d-xl-block"></div>
  <p class="color-gray-4 text-uppercase font-size-16 mb-0 mr-4 d-none d-xl-block">
    INV-00125
  </p>
  <button type="button" class="btn btn-primary btn-with-addon mr-auto text-nowrap d-none d-md-block">
    <span class="btn-addon">
      <i class="btn-addon-icon fe fe-plus-circle"></i>
    </span>
    New Request
  </button>
  <div class="amount mr-3 ml-auto d-none d-sm-flex">
    <p class="amountText">
      This month
      <span class="amountValue">$251.12</span>
    </p>
    <div class="amountGraph">
      <i class="amountGraphItem" style="height: 80%"></i>
      <i class="amountGraphItem" style="height: 50%"></i>
      <i class="amountGraphItem" style="height: 70%"></i>
      <i class="amountGraphItem" style="height: 60%"></i>
      <i class="amountGraphItem" style="height: 50%"></i>
      <i class="amountGraphItem" style="height: 65%"></i>
    </div>
  </div>
  <div class="amount d-none d-sm-flex">
    <p class="amountText">
      Last month
      <span class="amountValue">$12,256.12</span>
    </p>
    <div class="amountGraph">
      <i class="amountGraphItem" style="height: 60%"></i>
      <i class="amountGraphItem" style="height: 65%"></i>
      <i class="amountGraphItem" style="height: 75%"></i>
      <i class="amountGraphItem" style="height: 55%"></i>
      <i class="amountGraphItem" style="height: 100%"></i>
      <i class="amountGraphItem" style="height: 85%"></i>
    </div>
  </div>
</div>