import { Injectable } from '@angular/core'
import { Socket } from 'ngx-socket-io'
import { BehaviorSubject, Observable, Observer } from 'rxjs'
import { map } from 'rxjs/operators'
//import { io } from "socket.io-client";
import { HttpService } from '../../services/http/http.service'

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  chatGroups: any = []
  selectedGroup: any = false
  unreadNotificationsExist: any = false // this is for Red dot at bell icon
  chatUnreadCount = 0 //for left menu
  userData: any = {}
  onlineUsers: any = []

  constructor(private socket: Socket, public services: HttpService) {
    // this.socket.on('userStatus', (data) => {
    //   // let index = this.users.findIndex(user => user.username === data.username);
    //   // if (index === -1) {
    //   //   this.users.push({ username: data.username, status: data.status });
    //   // } else {
    //   //   this.users[index].status = data.status;
    //   // }
    // });
    // this.socket.emit('newUser', { username: this.username });
  }

  // setupSocketConnection() {
  //  this.socket = io('http://localhost:3000');
  //   this.socket.emit('new-message', 'Hello there from Angular.');
  // }

  public connectSocket(myRoomId, myId) {
    const rooms = {
      my_room_id: myRoomId,
      my_id: myId,
    }

    this.socket.emit('room-join', rooms)
  }

  public connectSocketOwn(myId) {
    this.socket.on('userStatus', data => {
      this.onlineUsers = data
      // let index = this.users.findIndex(user => user.username === data.username);
      // if (index === -1) {
      //   this.users.push({ username: data.username, status: data.status });
      // } else {
      //   this.users[index].status = data.status;
      // }
    })

    this.userData = this.services.userData
    this.socket.emit('newUser', this.userData.userId)

    this.socket.connect()
    this.socket.emit('own-room-join', myId)
  }

  public disconnectSocket() {
    this.socket.disconnect()
  }

  public sendMessage(message) {
    this.socket.emit('send-message', message)
  }

  public sendEmail(message) {
    this.socket.emit('send-email', message)
  }

  public replyEmail(message) {
    this.socket.emit('reply-email', message)
  }

  // public getNewMessage = () => {
  //   this.socket.on('message', (message) =>{
  //     this.message$.next(message);
  //   });
  //   return this.message$.asObservable();
  // };

  public getEmails = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-email', email => {
        observer.next(email)
      })
    })
  }

  public getReply = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-reply', email => {
        observer.next(email)
      })
    })
  }

  public getMessages = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-message', message => {
        observer.next(message)
      })
    })
  }
  public roomJoinSuccess = () => {
    this.socket.on('room-join-success', message => {})
  }
  public gotNewMessage = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-message-unread', message => {
        observer.next(message)
      })
    })
  }

  //test
  public editMessage(message) {
    this.socket.emit('edit-message', message)
  }

  //new-editedmessage
  public gotEditedMessage = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-editedmessage', message => {
        observer.next(message)
      })
    })
  }

  public deleteMessage(message) {
    this.socket.emit('delete-message', message)
  }

  //new-deletedmessage
  public gotDeletedMessage = () => {
    return new Observable((observer: Observer<object>) => {
      this.socket.on('new-deletedmessage', message => {
        observer.next(message)
      })
    })
  }

  public leaveFromRoom(data) {
    this.socket.emit('leave-room', data)
  }

  findUserAndShift(groupId) {
    this.chatGroups.unshift(
      this.chatGroups.splice(
        this.chatGroups.findIndex(elt => elt.group_id === groupId),
        1,
      )[0],
    )
    if (this.selectedGroup !== groupId) {
      this.chatGroups.forEach(user => {
        if (user.group_id === groupId) {
          user.unreadCount = user.unreadCount + 1
          user.lastMessage = new Date()
          user.messageOn = 'today'
          this.unreadNotificationsExist = true
          this.chatUnreadCount++
        }
      })
    }
  }

  onUserSelect() {
    let tempUnread = false
    this.chatGroups.forEach(each => {
      if (each.unreadCount) {
        this.unreadNotificationsExist = true
        tempUnread = true
      }
    })

    if (tempUnread) {
      this.unreadNotificationsExist = true
    } else {
      this.unreadNotificationsExist = false
      this.chatUnreadCount = 0
    }
  }
}
