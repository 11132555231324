<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <nz-badge [nzDot]="chatService.unreadNotificationsExist">
      <i class="icon fe fe-bell"></i>
    </nz-badge>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="card vb__utils__shadow width-350">
      <div class="card-body p-0">
  
        <div>
          <nz-tabset class="tabs vb-tabs-bordered" [nzSelectedIndex]="0">
            <nz-tab nzTitle="Chats">
              <div class="text-gray-6">
                <ul class="list-unstyled">
                  <span *ngFor="let item of chatService.chatGroups; let i = index;">
                    <li (click)="selectUser(item, i)" *ngIf="item.unreadCount" class="mb-3">
                      <div class="head">
                        <p class="title">
                          {{item.group_name}}
                          <span  class="flex-shrink-0 align-self-start unread">
                            <div class="badge badge-danger">{{item.unreadCount}}</div> 
                          </span>
                        </p>
                      </div>
                    </li>
                  </span>
                </ul>
              </div>
            </nz-tab>
      
          </nz-tabset>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>

</div>


<ng-template #template>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <div *ngIf="popUpMessage.groupName" class=" font-size-12 font-weight-bold text-truncate text-gray-6"> # {{
        popUpMessage.groupName}}</div>
      <span class="ant-notification-notice-icon">
        <div class="vb__utils__avatar vb__utils__avatar--size46 mr-3 flex-shrink-0">
          <img [src]='popUpMessage?.senderLogo' alt="User/Group" />
        </div>
      </span>
      <div class="ant-notification-notice-message ">
        <span class="text-dark font-size-15 font-weight-bold text-truncate ml-2">{{popUpMessage.senderName}} </span>
        <span class="ml-2">
          <quill-view-html [content]="popUpMessage?.msg" theme="snow"></quill-view-html>
        </span>
      </div>
      <!-- <div class="ant-notification-notice-description  ">
  
      </div>  -->
    </div>
  </div>



</ng-template>
<a id="popup-btn" style="display: none" (click)="createBasicNotificationTemplate(template)">link</a>