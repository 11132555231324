<div>
  <div class="height-300 d-flex flex-column justify-content-end">
    <perfect-scrollbar style="height: 100%">
      <div class="contentWrapper">
        <div class="message answer">
          <div class="messageContent">
            <div class="text-gray-4 font-size-12 text-uppercase">MoxieDB, Active now<nz-badge class="ml-2 pb-1" nzColor="green"></nz-badge></div>
            <div>Hello! Welcome to moxieDB. Please select the support type?</div>
            <nz-radio-group [(ngModel)]="selectedType" (ngModelChange)="onRadioChange($event)" class="pt-1"
              [disabled]="selectedType">
              <label nz-radio nzValue="Complaint">Complaint</label>
              <label nz-radio nzValue="Suggestion">Suggestion</label>
            </nz-radio-group>
          </div>
          <div class="avatar messageAvatar">
            <img src="../../../../../assets/favicon.png" alt="You" />
          </div>
        </div>
        <div class="message pb-2" *ngIf="selectedType">
          <div class="messageContent" style="background-color: #D4E1FF;">
            <div class="text-gray-4 font-size-12 text-uppercase">You<nz-badge class="ml-2 pb-1" nzColor="green"></nz-badge></div>
            <div>{{selectedType}}</div>
          </div>
          <div class="avatar messageAvatar">
            <img [src]="profilePic" />
          </div>
        </div>
        <div class="message answer" *ngIf="selectedType">
          <div class="messageContent">
            <div class="text-gray-4 font-size-12 text-uppercase">MoxieDB, Active now<nz-badge class="ml-2 pb-1" nzColor="green"></nz-badge></div>
            <div>Please enter the {{selectedType}} below</div>
          </div>
          <div class="avatar messageAvatar">
            <img src="../../../../../assets/favicon.png" alt="You" />
          </div>
        </div>
        
        <div class="message" *ngIf="sentMsg">
          <div class="messageContent" style="background-color: #D4E1FF;">
            <div class="text-gray-4 font-size-12 text-uppercase">You<nz-badge class="ml-2 pb-1" nzColor="green"></nz-badge></div>
            <div>{{message}}</div>
          </div>
          <div class="avatar messageAvatar">
            <img [src]="profilePic" />
          </div>
        </div>
        <div class="message answer" *ngIf="sentMsg">
          <div class="messageContent">
            <div class="text-gray-4 font-size-12 text-uppercase">MoxieDB, Active now<nz-badge class="ml-2 pb-1" nzColor="green"></nz-badge></div>
            <div>Thank you. We will get back to you shortly.</div>
            <button nz-button nzType="default" (click)="more()">More complaints/suggestions?</button>
          </div>
          <div class="avatar messageAvatar">
            <img src="../../../../../assets/favicon.png" alt="You" />
          </div>
        </div>

      </div>
    </perfect-scrollbar>
  </div>
  <div class="input-group pt-4 mb-3" *ngIf="selectedType && !sentMsg">
    <textarea type="text" class="form-control" placeholder="Enter message..." aria-label="Enter here..."
      [(ngModel)]="message" aria-describedby="button-addon2"></textarea>
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" (click)="sendMessage()">
        <i class="fe fe-send align-middle"></i>
      </button>
    </div>
  </div>
</div>