import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class TracktasksService {
  constructor() {}

  NewTracktasksCount = 0 //for left menu
}
