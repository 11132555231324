import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private locationChange = new BehaviorSubject('')
  changedLoc = this.locationChange
  userlocation = ''

  private subscriberName = new BehaviorSubject('')
  subscriber = this.subscriberName

  private signatureData = new BehaviorSubject({})
  signature = this.signatureData

  private breadCrumbsChagne = new BehaviorSubject([])
  changedBread = this.breadCrumbsChagne

  private organizationChange = new BehaviorSubject({ orgName: 'All', _id: 'All' })
  changedOrganization = this.organizationChange

  private moduleCheck = new BehaviorSubject('')
  moduleLoc = this.moduleCheck

  private chatGroupIdnotification = new BehaviorSubject('')
  ChatnotificationGroupId = this.chatGroupIdnotification

  private sicDataToCompose = new BehaviorSubject({})
  sicDataForRequest = this.sicDataToCompose

  changeLocation(loc: string) {
    this.locationChange.next(loc)
  }

  subscriberNameFromCollateral(collateral: string) {
    this.subscriberName.next(collateral)
  }

  subscriberNameFromintranet(intranet: string) {
    this.subscriberName.next(intranet)
  }

  signatureNameFromTopbar(signature: any) {
    this.signatureData.next(signature)
  }

  changebreadCrumbs(bread: any) {
    this.breadCrumbsChagne.next(bread)
  }

  changeOrganization(org: any) {
    this.organizationChange.next(org)
  }

  ChatGroupIdforNotificaion(grpId: any) {
    //taking orignal grp id from chat box cmpnt and passing it to user  cmpnt
    this.chatGroupIdnotification.next(grpId)
  }

  checkModuleLocation(loc: any) {
    //for disabling org filter
    this.moduleCheck.next(loc)
  }

  SicDataToComposeFunc(sicData: any) {
    this.sicDataToCompose.next(sicData)
  }

  constructor() {}
}
