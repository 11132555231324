import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HttpService } from '../../../../services/http/http.service'
import { MustMatch } from '../../../../helpers/must-match.vaidator'

function hasCapitalLetter(control: FormControl) {
  if (!/[A-Z]/.test(control.value)) {
    return { capitalLetterMissing: true }
  }
  return null
}

// Custom validator function for checking if a password has at least one symbol
function hasSymbol(control: FormControl) {
  if (!/[$@$!%*?&]/.test(control.value)) {
    return { symbolMissing: true }
  }
  return null
}

@Component({
  selector: 'vb-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent implements OnInit {
  name: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''

  isVisible = false
  isVisible1 = false
  isConfirmLoading: any = false
  isConfirmLoading1: any = false

  editProfileForm: FormGroup
  passChange: FormGroup

  fileToUpload: File = null
  profilePic: any = 'assets/images/default.png'
  profilePicTemp: any = 'assets/images/default.png'
  userData: any = {}

  prePassType: string = 'password'
  proPassType: string = 'password'
  rePassType: string = 'password'

  hasUppercase: any = false
  hasSpecialChar: any = false
  hasNumber: any = false
  min8: any = false
  isVisibleFirst = false

  constructor(
    private store: Store<any>,
    private services: HttpService,
    private fb: FormBuilder,
    private router: Router,
    private notification: NzNotificationService,
  ) {
    this.userData = JSON.parse(this.services.getItem('userData'))

    // sivagopi commented
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    //   this.name = state.name
    //   this.role = state.role
    //   this.email = state.email
    // })

    this.editProfileForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      workingLocation: [null, Validators.required],
    })
    this.passChange = this.fb.group(
      {
        prePass: ['', Validators.required],
        proPass: ['', [Validators.required, Validators.minLength(8), hasCapitalLetter, hasSymbol]],
        reProPass: ['', Validators.required],
        accessToken: [''],
      },
      {
        validator: MustMatch('proPass', 'reProPass'),
      },
    )
    if (this.services.userData) {
      this.editProfileForm.setValue({
        firstName: this.services.userData.firstName,
        lastName: this.services.userData.lastName,
        phone: this.services.userData.phone,
        workingLocation: this.services.userData.workingLocation,
      })

      this.name = this.services.userData.firstName
      this.role = this.services.playingRole
      this.email = this.services.userData.email
      this.phone = this.services.userData.phone
      if (this.services.userData.logoUrl) {
        this.profilePic = this.services.userData.logoUrl
        this.profilePicTemp = this.services.userData.logoUrl
      }
    }
  }

  ngOnInit(): void {
    const request = {
      method: 'GET',
      action_url: 'user/check_password_expiry',
    }
    this.services.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status && res.showAlert) {
          this.isVisibleFirst = true
        }
      },
      error => {
        if (error.status == 403) {
          this.router.navigate(['/auth/forbidden-page'])
        }
      },
    )
  }

  logout() {
    this.services.logout()
  }

  showModal(): void {
    this.isVisible = true
  }

  showModal1(): void {
    this.isVisible1 = true
  }

  handleCancel(): void {
    this.isVisible = false
  }

  handleCancel1(): void {
    this.isVisible1 = false
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0)

    const reader = new FileReader()
    reader.onload = e => (this.profilePicTemp = reader.result)

    reader.readAsDataURL(this.fileToUpload)
  }

  handleOk() {
    this.isConfirmLoading = true

    const formData = new FormData()
    formData.append('file', this.fileToUpload)
    formData.append('firstName', this.editProfileForm.value.firstName)
    formData.append('lastName', this.editProfileForm.value.lastName)
    formData.append('phone', this.editProfileForm.value.phone)
    formData.append('workingLocation', this.editProfileForm.value.workingLocation)

    const request = {
      params: formData,
      method: 'POST',
      action_url: 'user/update_profile_data',
    }
    this.services.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.isConfirmLoading = false
          this.isVisible = false

          res.data.orgLogo = this.userData.orgLogo
          res.data.orgName = this.userData.orgName
          // res.data.userContent = true
          res.data.userId = this.userData.userId

          this.services.userData = res.data
          this.services.setItem('userData', JSON.stringify(res.data))

          this.name = this.services.userData.firstName
          this.role = this.services.playingRole
          this.email = this.services.userData.email
          this.phone = this.services.userData.phone

          if (this.services.userData.logoUrl) {
            this.profilePic = this.services.userData.logoUrl
            this.profilePicTemp = this.services.userData.logoUrl
          }
          this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
          // window.location.reload()
        }
      },
      error => {
        if (error.status == 403) {
          this.router.navigate(['/auth/forbidden-page'])
        }
        this.isConfirmLoading = false

        this.notification.create('error', 'Failed!', error.error.msg, {
          nzPlacement: 'bottomRight',
        })
      },
    )
  }

  handleOk1() {
    this.isConfirmLoading1 = true

    const request = {
      params: this.passChange.value,
      method: 'POST',
      action_url: 'user/update_profile_password',
    }
    this.services.doHttp(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.isConfirmLoading1 = false
          this.isVisible1 = false
          this.isVisibleFirst = false
          this.passChange.reset()
          this.notification.create('success', 'Success!', res.msg, { nzPlacement: 'bottomRight' })
        }
      },
      error => {
        if (error.status == 403) {
          this.router.navigate(['/auth/forbidden-page'])
        }
        this.isConfirmLoading1 = false
        this.notification.create('error', 'Failed!', error.error.msg, {
          nzPlacement: 'bottomRight',
        })
      },
    )
  }

  togglePasswordVisibility(controlName: string) {
    this.prePassType = this.prePassType === 'password' ? 'text' : 'password'
  }

  togglePasswordVisibilityNew(controlName: string) {
    this.proPassType = this.proPassType === 'password' ? 'text' : 'password'
  }

  togglePasswordVisibilityConf(controlName: string) {
    this.rePassType = this.rePassType === 'password' ? 'text' : 'password'
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCopy(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onCut(event: ClipboardEvent): void {
    event.preventDefault()
  }

  onPasswordChange() {
    const password = this.passChange.value.proPass
    const uppercaseRegex = /[A-Z]/
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/
    const numberRegex = /[0-9]/
    const lengthRegex = /[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}/

    // Check if the password meets all the requirements
    this.hasUppercase = uppercaseRegex.test(password)
    this.hasSpecialChar = specialCharRegex.test(password)
    this.hasNumber = numberRegex.test(password)
    this.min8 = lengthRegex.test(password)
  }
}
