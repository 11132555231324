import { Component, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.scss'],
})
export class Error403Component implements OnInit {
  constructor(private location: Location, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  back() {
    this.router.navigate(['/user/dashboard'])
  }
}
