<!--<div>-->
  <!--<div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">-->
    <!--<nz-avatar nzIcon="user" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>-->
  <!--</div>-->
  <!--<nz-dropdown-menu #menu="nzDropdownMenu">-->
    <!--<ul nz-menu>-->
      <!--<li nz-menu-item>-->
        <!--<strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>-->
        <!--<div>-->
          <!--<strong class="mr-1">-->
            <!--{{ 'topBar.profileMenu.billingPlan' | translate }}:-->
          <!--</strong>-->
          <!--Professional-->
        <!--</div>-->
        <!--<div>-->
          <!--<strong>-->
            <!--{{ 'topBar.profileMenu.role' | translate }}:-->
          <!--</strong>-->
          <!--{{role || '—'}}-->
        <!--</div>-->
      <!--</li>-->
      <!--<li nz-menu-divider></li>-->
      <!--<li nz-menu-item>-->
        <!--<div>-->
          <!--<strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>-->
          <!--{{email || '—'}}-->
          <!--<br />-->
          <!--<strong>{{ 'topBar.profileMenu.phone' | translate }}: </strong>-->
          <!--{{phone || '—'}}-->
        <!--</div>-->
      <!--</li>-->
      <!--<li nz-menu-divider></li>-->
      <!--<li nz-menu-item>-->
        <!--<a href="javascript: void(0);">-->
          <!--<i class="fe fe-user mr-2"></i>-->
          <!--{{ 'topBar.profileMenu.editProfile' | translate }}-->
        <!--</a>-->
      <!--</li>-->
      <!--<li nz-menu-divider></li>-->
      <!--<li nz-menu-item>-->

        <!--<a href="javascript: void(0);" (click)="logout()">-->
          <!--<i class="fe fe-log-out mr-2"></i>-->
          <!--{{ 'topBar.profileMenu.logout' | translate }}-->
        <!--</a>-->
      <!--</li>-->
    <!--</ul>-->
  <!--</nz-dropdown-menu>-->
<!--</div>-->

<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <!--<nz-avatar nzIcon="profilePic" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>-->
    <img [src]="profilePic" class="ant-avatar ant-avatar-lg ant-avatar-lg">
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
        <div>
          <strong class="mr-1">
            {{ 'topBar.profileMenu.billingPlan' | translate }}:
          </strong>
          Professional
        </div>
        <div>
          <strong>
            {{ 'topBar.profileMenu.role' | translate }}:
          </strong>
          {{role || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <div>
          <strong>{{ 'topBar.profileMenu.email' | translate }}: </strong>
          {{email || '—'}}
          <br/>
          <strong>{{ 'topBar.profileMenu.phone' | translate }}: </strong>
          {{phone || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href="javascript: void(0);" (click)="showModal()">
          <i class="fe fe-user mr-2"></i>
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-item>
        <a href="javascript: void(0);" (click)="showModal1()">
          <i class="fa fa-key mr-2"></i>
          Change Password
        </a>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>

        <a href="javascript: void(0);" (click)="logout()">
          <i class="fe fe-log-out mr-2"></i>
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Your Profile" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>

    <div class="col-md-8" style="float:left;">
      <form [formGroup]="editProfileForm">
        <div>
          <mat-form-field appearance="outline" class="col-md-6" style="padding-left: 5px; padding-right: 5px">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName"/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-6" style="padding-left: 5px; padding-right: 5px">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName"/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-12" style="padding-left: 5px; padding-right: 5px">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone"/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-12" style="padding-left: 5px; padding-right: 5px">
            <mat-label>Working Location</mat-label>
            <input matInput formControlName="workingLocation"/>
          </mat-form-field>

        </div>
      </form>
    </div>
    <div class="col-md-4" style="position: inherit; float: right">
      <div class="d-flex flex-wrap flex-column align-items-center">
        <div class="vb__utils__avatar vb__utils__avatar--size64 mb-3 avator-resize"><img
          [src]="profilePicTemp" alt="Mary Stanform"></div>
        <div class="text-center">
          <button nz-button nzType="primary" (click)="hiddenfileinput.click()">Upload an Image</button>
          <input style="display: none" type="file" (change)="handleFileInput($event.target.files)" #hiddenfileinput>
        </div>
      </div>
    </div>

  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Save Changes</button>
  </div>
</nz-modal>

<nz-modal [(nzVisible)]="isVisible1" nzTitle="Change Password" (nzOnCancel)="handleCancel1()"
          (nzOnOk)="handleOk1()">
  <ng-container *nzModalContent nzWidth="100">

    <div class="col-md-12" style="float:left;">
      <form [formGroup]="passChange">
        <div>
          <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Old Password</mat-label>
            <input matInput formControlName="prePass" (paste)="onPaste($event)" (copy)="onCopy($event)" (cut)="onCut($event)" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>New Password</mat-label>
            <input matInput formControlName="proPass" (paste)="onPaste($event)" (copy)="onCopy($event)" (cut)="onCut($event)" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-12">
            <mat-label>Confirm Password</mat-label>
            <input matInput formControlName="reProPass" (paste)="onPaste($event)" (copy)="onCopy($event)" (cut)="onCut($event)"/>
          </mat-form-field>
        </div>
      </form>
    </div>

  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel1()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleOk1()" [nzLoading]="isConfirmLoading1">Update Password</button>
  </div>
</nz-modal>

