<div>
  <div nz-dropdown nzTrigger="click" class="dropdown" [nzDropdownMenu]="menu">
    <!--<nz-avatar nzIcon="profilePic" [nzShape]="'square'" [nzSize]="'large'" class="avatar"></nz-avatar>-->
    <img [src]="profilePic" class="ant-avatar ant-avatar-lg ant-avatar-lg" />
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <span>{{ 'topBar.profileMenu.hello' | translate }} {{name || 'Anonymous'}}!</span>
        <div>
          <span class="mr-1">
            {{ 'topBar.profileMenu.billingPlan' | translate }}:
          </span>
          Professional
        </div>
        <div>
          <span>
            {{ 'topBar.profileMenu.role' | translate }}:
          </span>
          {{role || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <div>
          <span>{{ 'topBar.profileMenu.email' | translate }}: </span>
          {{email || '—'}}
          <br />
          <span>{{ 'topBar.profileMenu.phone' | translate }}: </span>
          {{phone || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a href="javascript: void(0);" (click)="showModal()">
          <i class="fe fe-user mr-2"></i>
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-item>
        <a href="javascript: void(0);" (click)="showModal1()">
          <i class="fa fa-key mr-2"></i>
          Change Password
        </a>
      </li>


      <li nz-menu-item>
        <a href="https://moxiedb.com/legal/copyright.html" target="_blank">
          <mat-icon class="icon">copyright</mat-icon>Copyright
        </a>
      </li>
      <li nz-menu-item>
        <a href="https://moxiedb.com/legal/privacy-policy.html" target="_blank" rel="noopener noreferrer">
          <mat-icon class="icon">verified_user</mat-icon>Privacy Policy
        </a>
      </li>
      <li nz-menu-item>
        <a href="https://moxiedb.com/legal/cookies.html" target="_blank" rel="noopener noreferrer">
          <mat-icon class="icon">settings_input_svideo</mat-icon>Cookies Policy
        </a>
      </li>



      <li nz-menu-divider></li>
      <li nz-menu-item>

        <a href="javascript: void(0);" (click)="logout()">
          <i class="fe fe-log-out mr-2"></i>
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Edit Your Profile" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  [nzStyle]="{ top: '20px'}" nzWidth="700px">
  <ng-container *nzModalContent>
    <div class="row">
      <div class="col-md-8" style="float:left;">
        <form [formGroup]="editProfileForm">
          <div>
            <mat-form-field appearance="outline" class="col-md-6" style="padding-left: 5px; padding-right: 5px">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-6" style="padding-left: 5px; padding-right: 5px">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12" style="padding-left: 5px; padding-right: 5px">
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12" style="padding-left: 5px; padding-right: 5px">
              <mat-label>Working Location</mat-label>
              <input matInput formControlName="workingLocation" />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="col-md-4" style="position: inherit; float: right">
        <div class="d-flex flex-wrap flex-column align-items-center">
          <div class="vb__utils__avatar vb__utils__avatar--size64 mb-3 avator-resize"><img [src]="profilePicTemp"
              alt="Mary Stanform"></div>
          <div class="text-center">
            <button nz-button class="button-10" (click)="hiddenfileinput.click()">Upload an Image</button>
            <input style="display: none" type="file" (change)="handleFileInput($event.target.files)" #hiddenfileinput>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="text-center">
    <button nz-button class="button-10" (click)="handleCancel()">Cancel</button>
    <button nz-button class="button-10" (click)="handleOk()" [nzLoading]="isConfirmLoading">Save Changes</button>
  </div>
</nz-modal>

<nz-modal [(nzVisible)]="isVisible1" nzTitle="Change Password" (nzOnCancel)="handleCancel1()" (nzOnOk)="handleOk1()"
  [nzStyle]="{ top: '20px'}" nzWidth="500px">
  <ng-container *nzModalContent nzWidth="100">
    <div class="row">
      <div class="col-md-12" style="float:left;">
        <form [formGroup]="passChange">
          <div>
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>Old Password</mat-label>
              <input matInput formControlName="prePass" [type]="prePassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" />
              <mat-icon matSuffix (click)="togglePasswordVisibility('prePass')">{{ prePassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>New Password</mat-label>
              <input matInput formControlName="proPass" [type]="proPassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" (input)="onPasswordChange()" />
               <mat-icon matSuffix (click)="togglePasswordVisibilityNew('proPass')">{{ proPassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
              <!--<mat-error
                *ngIf="passChange.get('proPass').hasError('required') && !passChange.get('proPass').hasError('minlength') && !passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                is required</mat-error>
              <mat-error
                *ngIf="passChange.get('proPass').hasError('minlength') && !passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                must be at least 8 characters long</mat-error>
              <mat-error
                *ngIf="passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                must contain at least one capital letter</mat-error>
              <mat-error *ngIf="passChange.get('proPass').hasError('symbolMissing')">Password must contain at least one
                symbol</mat-error> -->
            </mat-form-field>

            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!min8?'#f5222e': '#41b883'">
              <i *ngIf="min8" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!min8" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">8 characters minimum</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasNumber?'#f5222e': '#41b883'">
              <i *ngIf="hasNumber" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasNumber" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">must contain one number</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasUppercase?'#f5222e': '#41b883'">
              <i *ngIf="hasUppercase" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasUppercase" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">one uppercase</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasSpecialChar?'#f5222e': '#41b883'">
              <i *ngIf="hasSpecialChar" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasSpecialChar" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">one special character</div>
            </div>

            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="reProPass" [type]="rePassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" />
              <mat-icon matSuffix (click)="togglePasswordVisibilityConf('rePass')">{{ rePassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
              <mat-error *ngIf="passChange.get('proPass') != passChange.get('reProPass')">Passwords do not
                match</mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="text-center">
    <button nz-button class="button-10" (click)="handleCancel1()">Cancel</button>
    <button nz-button class="button-10" (click)="handleOk1()" [nzLoading]="isConfirmLoading1"
      [disabled]="!passChange.valid">Update Password</button>
  </div>
</nz-modal>

<!-- first time login -->
<nz-modal [(nzVisible)]="isVisibleFirst" nzTitle="Change Password" (nzOnCancel)="handleCancel1()" (nzOnOk)="handleOk1()" [nzClosable]="false"
  [nzStyle]="{ top: '20px'}" nzWidth="500px">
  <ng-container *nzModalContent nzWidth="100">
    <div class="row">
      <div class="col-md-12" style="float:left;">
        <form [formGroup]="passChange">
          <div>
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>Old Password</mat-label>
              <input matInput formControlName="prePass" [type]="prePassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" />
              <mat-icon matSuffix (click)="togglePasswordVisibility('prePass')">{{ prePassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>New Password</mat-label>
              <input matInput formControlName="proPass" [type]="proPassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" (input)="onPasswordChange()" />
               <mat-icon matSuffix (click)="togglePasswordVisibilityNew('proPass')">{{ proPassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
              <!--<mat-error
                *ngIf="passChange.get('proPass').hasError('required') && !passChange.get('proPass').hasError('minlength') && !passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                is required</mat-error>
              <mat-error
                *ngIf="passChange.get('proPass').hasError('minlength') && !passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                must be at least 8 characters long</mat-error>
              <mat-error
                *ngIf="passChange.get('proPass').hasError('capitalLetterMissing') && !passChange.get('proPass').hasError('symbolMissing')">Password
                must contain at least one capital letter</mat-error>
              <mat-error *ngIf="passChange.get('proPass').hasError('symbolMissing')">Password must contain at least one
                symbol</mat-error> -->
            </mat-form-field>

            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!min8?'#f5222e': '#41b883'">
              <i *ngIf="min8" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!min8" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">8 characters minimum</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasNumber?'#f5222e': '#41b883'">
              <i *ngIf="hasNumber" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasNumber" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">must contain one number</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasUppercase?'#f5222e': '#41b883'">
              <i *ngIf="hasUppercase" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasUppercase" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">one uppercase</div>
            </div>
            <div style="width: 100%; display: -webkit-inline-flex;padding:5px 15px; font-weight: 400"
              [style.color]="!hasSpecialChar?'#f5222e': '#41b883'">
              <i *ngIf="hasSpecialChar" nz-icon nzType="check" nzTheme="outline"></i>
              <i *ngIf="!hasSpecialChar" nz-icon nzType="close" nzTheme="outline"></i>
              <div style="margin-top: -4px;margin-left: 5px;">one special character</div>
            </div>

            <mat-form-field appearance="outline" class="col-md-12">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="reProPass" [type]="rePassType" (paste)="onPaste($event)"
                (copy)="onCopy($event)" (cut)="onCut($event)" />
              <mat-icon matSuffix (click)="togglePasswordVisibilityConf('rePass')">{{ rePassType === 'password' ?
                'visibility_off' :
                'visibility' }}</mat-icon>
              <mat-error *ngIf="passChange.get('proPass') != passChange.get('reProPass')">Passwords do not
                match</mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter class="text-center">
    <!-- <button *ngIf="!checkClose" nz-button class="button-10" (click)="handleCancel1()">Cancel</button> -->
    <button nz-button class="button-10" (click)="handleOk1()" [nzLoading]="isConfirmLoading1"
      [disabled]="!passChange.valid">Update Password</button>
  </div>
</nz-modal>
